import Utils from "../../../utility";
import moment from "moment";
export const getPOkList = (setOpen) => {
  return (dispatch, getState) => {
    const {
      order,
      sortField,
      offset,
      take,
      search,
      startDate,
      endDate,
      storeId,
      vender,
    } = getState().POReducer;
    // const {} = value;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.PO,
      `?order=${order}&offset=${offset}&take=${take}&startDate=${
        startDate ? moment(startDate).format("YYYY-MM-DD") : ""
      }&endDate=${
        endDate ? moment(endDate).format("YYYY-MM-DDT23:59:59") : ""
      }&store=${storeId ? storeId : ""}&vendor=${
        vender ? vender : ""
      }&sortField=${sortField ? sortField : ""}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.PO,
          payload: {
            POList: respData.data.purchaseOrders,
            count: respData.data.count,
          },
        });
        setOpen(false);
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.PO} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};

/***get b2b Po List */
export const getB2BPOkList = () => {
  return (dispatch, getState) => {
    const {
      order,
      sortField,
      offset,
      take,
      search,
      startDate,
      endDate,
      storeId,
      status,
      vendor,
    } = getState().POReducer;
    // const {} = value;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.vendorPOHistory,
      `?order=${order}&offset=${offset}&take=${take}&startDate=${
        startDate ? moment(startDate).format("YYYY-MM-DD 00:00:01") : ""
      }&endDate=${
        endDate ? moment(endDate).format("YYYY-MM-DD 23:59:59") : ""
      }&store=${storeId ? storeId : ""}&vendor=${
        vendor ? vendor : ""
      }&sortField=${sortField ? sortField : ""}&search=${search}${
        status ? `&status=${status}` : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.PO,
          payload: {
            b2bPOList: respData.data.products,
            count: respData.data.count,
          },
        });
        // setOpen(false);
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.PO} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};

/***get b2b Po detail */
export const getB2BPOkDetails = (id, vendorId) => {
  return (dispatch, getState) => {
    // const {
    //   order,
    //   sortField,
    //   offset,
    //   take,
    //   search,
    //   startDate,
    //   endDate,
    //   storeId,
    //   vender,
    // } = getState().POReducer;
    // const {} = value;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.vendorPOHistory + id,
      `?vendorId=${vendorId}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.PO,
          payload: {
            detailB2BPO: respData.data,
            // count: respData.data.count,
          },
        });
        // setOpen(false);
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.PO} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};

/**status update of orders */
export const StatusUpdate = (id, val) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const {
      vendorInvoice,
      status,
      performaInvoice,
      proofOfDelivery,
      proofOfPayment,
    } = val;
    let str = `${id}`;
    // debugger;
    if (status) str += `?status=${status}`;
    if (vendorInvoice) str += `&vendorInvoice=${vendorInvoice}`;
    // debugger;
    if (performaInvoice) str += `&performaInvoice=${performaInvoice}`;
    if (proofOfDelivery) str += `&proofOfDelivery=${proofOfDelivery}`;
    if (proofOfPayment) str += `&proofOfPayment=${proofOfPayment}`;
    Utils.api.putApiCall(
      Utils.endPoints.vendorPOHistory + str,
      {},
      (respData) => {
        // setOpen(false);
        Utils.showAlert(1, "Status updated successfully");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        str = "";
        dispatch(getB2BPOkList());
        // navigate(-1);
        // dispatch(getPOkList(storeId));
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
      },
      (error) => {
        let { data } = error;
        str = "";
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.vendorPOHistory} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(false);
        // setSubmitting(true);
      }
    );
  };
};
export const getPOById = (id, storeId, callback = () => {}) => {
  return (dispatch, getState) => {
    // const { order, sortField, offset, take, search } =
    //   getState().warehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      Utils.endPoints.POSingle + id + `?storeId=${storeId ? storeId : ""}`,
      {},
      (respData) => {
        callback(respData.data?.purchaseOrder);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.PO,
          payload: {
            PODetail: respData.data?.purchaseOrder,
            // count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.POSingle} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
/**create invoices */

// invoicePdf

export const invoicePdf = (orderId, vendorId) => {
  return (dispatch, getState) => {
    // const { order, sortField, offset, take, search } =
    //   getState().warehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.invoicePdf,
      `?po=${orderId}&vendorId=${vendorId}`,
      (respData) => {
        // callback(respData.data?.purchaseOrder);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.PO,
          payload: {
            invoiceHtmlPo: respData.data,
            // count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.POSingle} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const createInvoicePdf = (po, vendor) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // debugger;
    const dataToSend = {
      po: `${po}`,
      vendorId: `${vendor}`,
    };

    Utils.api.postApiCall(
      Utils.endPoints.createInvoice,
      dataToSend,
      (respData) => {
        dispatch(invoicePdf(po, vendor));

        // setOpen(false);
        // debugger;
        // dispatch(invoicePdf(respData?.data?.po?.id, values?.vendor));
        Utils.showAlert(1, "Invoice Created Successfully");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/invoice-b2b");
        // dispatch(getPOkList(storeId));
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.createPo} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // dispatch(invoicePdf("112", "1053"));

        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(false);
        // setSubmitting(true);
      }
    );
  };
};
export const createInvoices = (
  values,
  setOpen,
  storeId,
  setSubmitting,
  navigate
) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // debugger;
    const dataToSend = {
      products: values.product.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
          sellingPrice: item.productSellingPrice,
          costPrice: item.productCostPrice,
          // vendor: item.vendor,
        };
      }),
      store: values.store,
      vendor: values.vendor,
      terms: values.terms,
      // deliveryDate: values.deliveryDate,
      // expiryDate: values.deliveryDate,
      type: values.type,
      po: values.po,
      // vendorId: values.vendor,
      // vehicleNo: values.vehicle,
      // invoiceNumber: values.invoice,
      // PO: values.PONumber,
      // store: `${storeId}`,
      // warehouse: "",
    };
    // debugger;
    // dataToSend.product = [
    //   {
    //     product: values.product,
    //     quantity: values.quantity,
    //   },
    // ];
    // delete dataToSend.quantity;
    // dataToSend.warehousePincode = +values.warehousePincode;
    // dataToSend.isDeleted = false;

    Utils.api.postApiCall(
      Utils.endPoints.b2bVendor,
      dataToSend,
      (respData) => {
        // setOpen(false);
        // debugger;
        dispatch(
          createInvoicePdf(respData?.data?.po?.poNumber, values?.vendor)
        );
        // dispatch(
        //   createInvoicePdf(orderId, vendorId)
        // );

        Utils.showAlert(1, "PO added successfully");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        navigate("/invoice-b2b");
        // dispatch(getPOkList(storeId));
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.createPo} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // dispatch(invoicePdf("112", "1053"));

        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        setSubmitting(false);
        // setSubmitting(true);
      }
    );
  };
};

/**get invoice list */

export const getInvoices = () =>
  // values,
  // setOpen,
  // storeId,
  // setSubmitting,
  // navigate
  {
    return (dispatch, getState) => {
      const {
        order,
        sortField,
        offset,
        take,
        search,
        startDate,
        endDate,
        storeId,
        vendor,
      } = getState().POReducer;
      // debugger;
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: { loading: true },
      });
     
      Utils.api.getApiCall(
        Utils.endPoints.invoice,
        `?order=${order}&offset=${offset}&take=${take}&search=${
          search ? search : ""
        }${vendor ? `&vendorId=${vendor}`:""}`,
        (respData) => {
          
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: false },
          });
          dispatch({
            type: Utils.ActionName.PO,
            payload: {
              invoiceList: respData.data,
              // count: respData.data.count,
            },
          });
          // navigate(-1);
          // dispatch(getPOkList(storeId));
          // resetForm({});
          // setIsEdit(null)
          // dispatch({type:Utils.ActionName.USER_LIST
          //   ,payload:{userlist:respData.data}})
        },
        (error) => {
          let { data } = error;
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: false },
          });
          Utils.showAlert(
            2,
            `${Utils.endPoints.createPo} POST Method error ${
              JSON.stringify(data?.message) || data?.error
            }`
          );
          // Utils.showAlert(2, data?.message?.join(",") || data?.error);
          // setSubmitting(false);
          // setSubmitting(true);
        }
      );
    };
  };
/*****end */

/**get invoice detail */

export const getInvoiceDetails = (id) =>
  // values,
  // setOpen,
  // storeId,
  // setSubmitting,
  // navigate
  {
    return (dispatch, getState) => {
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: { loading: true },
      });
      // debugger;

      // dataToSend.product = [
      //   {
      //     product: values.product,
      //     quantity: values.quantity,
      //   },
      // ];
      // delete dataToSend.quantity;
      // dataToSend.warehousePincode = +values.warehousePincode;
      // dataToSend.isDeleted = false;

      Utils.api.getApiCall(
        Utils.endPoints.detailInvoice,
        `${id}`,
        (respData) => {
          // setOpen(false);
          // Utils.showAlert(1, "PO added successfully");
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: false },
          });
          dispatch({
            type: Utils.ActionName.PO,
            payload: {
              invoiceDetail: respData.data.vInvoiceProducts,
              count: respData.data.count,
            },
          });
          // navigate(-1);
          // dispatch(getPOkList(storeId));
          // resetForm({});
          // setIsEdit(null)
          // dispatch({type:Utils.ActionName.USER_LIST
          //   ,payload:{userlist:respData.data}})
        },
        (error) => {
          let { data } = error;
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: false },
          });
          Utils.showAlert(
            2,
            `${Utils.endPoints.createPo} POST Method error ${
              JSON.stringify(data?.message) || data?.error
            }`
          );
          // Utils.showAlert(2, data?.message?.join(",") || data?.error);
          // setSubmitting(false);
          // setSubmitting(true);
        }
      );
    };
  };
// get
export const createPO = (values, setOpen, storeId, setSubmitting, navigate) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // debugger;
    const dataToSend = {
      product: values.product.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
          productSellingPrice: item.productSellingPrice,
          productCostPrice: item.productCostPrice,
          vendor: item.vendor,
        };
      }),
      store: values.store,
      vendor: values.vendor,
      terms: values.terms,
      deliveryDate: values.deliveryDate,
      expiryDate: values.deliveryDate,
      type: values.type,
      // vendorId: values.vendor,
      // vehicleNo: values.vehicle,
      // invoiceNumber: values.invoice,
      // PO: values.PONumber,
      // store: `${storeId}`,
      // warehouse: "",
    };
    // dataToSend.product = [
    //   {
    //     product: values.product,
    //     quantity: values.quantity,
    //   },
    // ];
    // delete dataToSend.quantity;
    // dataToSend.warehousePincode = +values.warehousePincode;
    // dataToSend.isDeleted = false;

    Utils.api.postApiCall(
      Utils.endPoints.createPo,
      dataToSend,
      (respData) => {
        // setOpen(false);
        Utils.showAlert(1, "PO added successfully");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        navigate(-1);
        dispatch(getPOkList(storeId));
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.createPo} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        setSubmitting(false);
        // setSubmitting(true);
      }
    );
  };
};

// export const deleteStoreStock = (id, storeId) => {
//   return (dispatch) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     Utils.api.deleteApiCall(
//       Utils.endPoints.storeStock + `/${id}`,
//       "",
//       (respData) => {
//         Utils.showAlert(1, "Warehouse stock delete sucesfuly");
//         dispatch(getPOkList(storeId));
//         // dispatch({type:Utils.ActionName.USER_LIST
//         //   ,payload:{userlist:respData.data}})
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getBrandList = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.brands,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.USER_LIST,
//           payload: { brands: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getPropertyOwner = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.propertyOwner,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.PROPERTY_OWNER,
//           payload: { propertyOwnerList: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

/**file upload */
export const uploadFile = (values, callback = () => {}) => {
  return (dispatch, getState) => {
    // const { order, sortField, offset, take, search } =
    //   getState().warehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { vendorId, storeId, file, type } = values;
    // debugger;
    var dataToSend = new FormData();
    dataToSend.append("file", file);
    dataToSend.append("storeId", storeId);
    dataToSend.append("vendorId", vendorId);
    dataToSend.append("type", type);
    Utils.api.postApiCall(
      Utils.endPoints.blobUpload,
      dataToSend,

      (respData) => {
        callback(respData.data);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // dispatch({
        //   type: Utils.ActionName.PO,
        //   payload: {
        //     PODetail: respData.data?.purchaseOrder,
        //     // count: respData.data.count,
        //   },
        // });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.POSingle} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
