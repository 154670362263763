import * as React from "react";
import Box from "@mui/material/Box";
import { Divider, Typography, Button, MenuItem } from "@mui/material";
// import QRCode from "qrcode.react";
// import { QRCodeCanvas } from "qrcode.react";
import Utils from "../../../utility";
import {
  cancelOrder,
  trackStatusOfOrders,
  updateRazorPayToken,
  zomatoUpdate,
} from "./action";
import Grid from "@mui/material/Grid";
// import AddItemButton from "../../component/button";
import RedOutLinButton from "../../../component/button/redOutlineButton";
import Select from "../../../component/select";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateOrderStatus,
  getOrdersById,
  orderInvoice,
  asignRunner,
} from "./action";
import { baseUrl } from "../../../utility/constant";
import axios from "axios";
import { getUserTypeFromLs } from "../../../utility/commonFunction";
import moment from "moment";
import NormalButton from "../../../component/button";
const Index = (props) => {
  const {
    id,
    orderId,
    orderDate,
    token,
    customerName,
    phoneNumber,
    orderAmountNet,
    orderAmountTotal,
    addressLine1,
    addressLine2,
    lat,
    runnerId,
    stateInvoiceNo,
    long,
    totalQuantity,
    orderStatus,
    products,
    storeName,
    storeId,
    pickupMode,
    internal_order_id,
    index,
    zomato,
  } = props?.data;
  const navigate = useNavigate();
  // console.log(pickupMode, "pickupMode");
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const formattedFromDate = oneWeekAgo.toISOString();
  const formattedToDate = currentDate.toISOString();

  const { filterdOrder } = useSelector((state) => state.onlineOrdersReducer);
  const dispatch = useDispatch();
  const [updateToken, setUpdateToken] = React.useState(false);
  const [razorToken, setRazorToken] = React.useState("");
  const [rider, setRider] = React.useState("");
  const [zomatoData, setZomatoData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalAmount, setToatalAmount] = React.useState(0);
  // const [totalItem, setTotalItem] = React.useState(0);
  const { runner: runnerList } = useSelector((state) => state.commonReducer);
  // const handleChangeQuantity = (e, i) => {
  //   let data = filterdOrder[props.index];

  //   let productData = data?.products[i];
  //   productData.quantity = e.target.value;
  //   dispatch({
  //     type: Utils.ActionName.ORDERS,
  //     payload: {
  //       filterdOrder: filterdOrder,
  //       // count: respData?.data?.count,
  //     },
  //   });

  //   let newCount = 0;
  //   let newTotal = 0;
  //   data?.products.forEach((element) => {
  //     newCount = newCount + +element.quantity;
  //     newTotal = +element.quantity * element.sellingPrice + newTotal;
  //   });
  //   setTotalItem(newCount);
  //   setToatalAmount(newTotal);
  //   // dispatch(
  //   //   addNewItemInOrder({
  //   //     orderId: id,
  //   //     products: [{ id: productData.id, quantity: e.target.value }],
  //   //     // orderStatus,
  //   //     orderAmountTotal: newTotal,
  //   //   })
  //   // );
  // };

  const productChangeStatus = (i, status) => {
    let data = filterdOrder[props.index];
    let productData = data?.products[i];
    // if(status==="reject"){
    // }
    productData.status = status;
    dispatch({
      type: Utils.ActionName.ONLINE_ORDERS,
      payload: {
        filterdOrder: filterdOrder,
        // count: respData?.data?.count,
      },
    });
    // console.log(
    //   data?.products.filter((row) => row.status === "approve").length,
    //   data,
    //   "approve"
    // );
    // if (
    //   data?.products?.filter(
    //     (row) => row?.status === "packed" || row?.status === "reject"
    //   ).length === data?.products.length
    // ) {
    //   dispatch(updateOrderStatus(id, "packed",getStoreId,formattedFromDate,formattedToDate));
    // }
    if (
      data?.products.filter(
        (row) => row?.status === "packed" || row?.status === "reject"
      ).length === data?.products.length
    ) {
      dispatch(updateOrderStatus(id, storeId, "packed"));
    }
  };
  React.useEffect(() => {
    if (runnerId) {
      setRider(runnerId);
    }
  }, []);
  const deliveryPage = () => {
    dispatch(getOrdersById({ orderId: id }));
    navigate(`/detail-page-for-delivery/${id}`);
  };
  const trackZomato = (orderId, data) => {
    // debugger;
    // if (Number(data.externalOrderID) === orderId) {
    setIsLoading(true);
    const postData = {
      internal_order_id: data?.internalOrderID,
    };

    const apiUrl = "https://uatservices.thenewshop.in/api/v1/zomato/track";

    axios
      .post(apiUrl, postData)
      .then((response) => {
        // debugger;
        setIsLoading(false);
        if (response.data.status.code === 500) {
          Utils.showAlert(2, response.data.errors);
        } else {
          setZomatoData(response.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
    // }
  };

  const handleClick = (data) => {
    let riderDataRes;
    if (data === "Zomato Rider") {
      riderDataRes = "zomato";
    } else if (data === "Store Rider") {
      riderDataRes = "store";
    }
    dispatch(
      zomatoUpdate(
        id,
        storeId,
        riderDataRes,
        formattedFromDate,
        formattedToDate
      )
    );
  };
  const titleAndItsDetail = (name, desc) => (
    <Grid item xs={12} sm={6} lg={4}>
      <Typography variant="subtitle1" className="color-label">
        {name}
      </Typography>
      <Typography variant="body3" className="m-b-5" sx={{ display: "block" }}>
        {desc}
      </Typography>
    </Grid>
  );
  return (
    <>
      {orderStatus !== "delivered" && orderStatus !== "cancelled" && (
        <>
          <div className="bg-white Dflex">
            <Grid
              // container
              rowSpacing={3}
              columnSpacing={2}
              className="fl-dir-c "
              sx={{ flex: 0.7 }}
            >
              {titleAndItsDetail(" Store name", storeName)}
              {titleAndItsDetail("Order No", id)}
              {/* {console.log(orderDate, "orderDate")} */}
              {titleAndItsDetail(
                "Order Time",
                moment(orderDate)
                  .subtract({ hours: 5, minutes: 30 })
                  .format("YYYY-MM-DD || hh:mm A")
              )}
              {titleAndItsDetail("Customer Name", customerName)}
              {titleAndItsDetail("Customer No.", phoneNumber)}
              {pickupMode !== "self"
                ? titleAndItsDetail(
                    "Customer Address",
                    `${addressLine1},  ${addressLine2}`
                  )
                : titleAndItsDetail("Customer Address", "Store Pickup")}
              {titleAndItsDetail()}

              {/* <p>
                {pickupMode !== "self" ? (
                  <p>
                    <b>Customer Address</b>:{" "}
                    {`${addressLine1},  ${addressLine2}`}
                  </p>
                ) : (
                  <p>
                    <b>Customer Address</b>: Store Pickup
                  </p>
                )}
              </p> */}
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                flex: 2,
                m: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ flex: 3 }}>
                {products?.length &&
                  products?.map((item, i) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={i}
                        // style={
                        //   item?.status === "packed" ? { paddingRight: 40 } : {}
                        // }
                      >
                        {/* <Box>
                        <b>{`(${item?.barcode})`}</b>
                        {item?.name} {`(${item.sellingPrice})`}
                      </Box> */}
                        {/* <hr className="m-t-20 m-b-20" /> */}

                        <Grid className="Dflex w-100">
                          <Typography
                            variant="body2"
                            sx={{ flex: 3.2, fontWeight: 500 }}
                          >
                            {item?.name}{" "}
                            <span
                              className="color-label"
                              style={{ fontSize: 13 }}
                            >{`${item?.barcode}:-`}</span>
                          </Typography>
                          <Typography variant="body2" sx={{ flex: 1 }}>
                            {" "}
                            {`${item?.quantity} * ${item.sellingPrice} =  ${
                              item?.quantity * item.sellingPrice
                            }`}
                          </Typography>
                        </Grid>
                        {/* <Typography variant="body2" sx={{ alignItems: "center" }}>
                        &nbsp; &nbsp;
                      </Typography> */}

                        {/* <IconButton
                      aria-label="delete"
                      // onClick={() => productChangeStatus(i, "reject")}
                    >
                      <ClearIcon />
                    </IconButton> */}
                        {/* <input
                          type="number"
                          value={item?.quantity}
                          disabled={
                            true
                            // orderStatus !== "placed" ||
                            // (orderStatus === "placed" && item.status === "packed")
                          }
                          style={{ width: 50, padding: 5 }}
                          onChange={(e) => handleChangeQuantity(e, i)}
                        /> */}
                        {/* {orderStatus !== "placed" ||
                        (orderStatus === "placed" &&
                          item.status === "packed") ? (
                          <IconButton
                            sx={{ width: 20, height: 20 }}
                          ></IconButton>
                        ) : (
                          <IconButton
                            sx={{ width: 20, height: 20 }}
                            aria-label="delete"
                            onClick={() => productChangeStatus(i, "packed")}
                          >
                            <CheckCircleOutlineIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        )} */}
                      </Box>
                      <Divider />
                    </>
                  ))}
                <Divider className="m-t-30" />
                <Grid className="Dflex w-100 ">
                  <Typography variant="body2" sx={{ flex: 3.2 }}>
                    Total quantity :-
                  </Typography>
                  <Typography variant="body2" sx={{ flex: 1, fontWeight: 500 }}>
                    {products?.reduce(
                      (quantity, item) => (quantity = quantity + item.quantity),
                      0
                    )}
                  </Typography>
                </Grid>
                <Divider />
                {pickupMode !== "self" ? (
                  <Grid className="Dflex w-100">
                    <Typography variant="body2" sx={{ flex: 3.2 }}>
                      Delivery Charge :-
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ flex: 1, fontWeight: 500 }}
                    >
                      45
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                <Divider />
                <Grid className="Dflex w-100">
                  <Typography variant="body2" sx={{ flex: 3.2 }}>
                    Total :-
                  </Typography>
                  <Typography variant="body2" sx={{ flex: 1, fontWeight: 500 }}>
                    {pickupMode !== "self"
                      ? +orderAmountTotal + 45
                      : +orderAmountTotal + 0}
                  </Typography>
                </Grid>
                <Divider />
                <Box sx={{ mt: 2 }}>
                  RazorPay Token:- {token ? token : "Pending"}
                  {updateToken ? (
                    <input
                      type="text"
                      value={razorToken}
                      onChange={(e) => setRazorToken(e.target.value)}
                      style={{ border: "1px solid gray" }}
                    />
                  ) : (
                    <></>
                  )}
                  {!token ? (
                    <span
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        padding: "0px 5px",
                        fontWaight: 700,
                      }}
                      onClick={
                        razorToken
                          ? () =>
                              dispatch(
                                updateRazorPayToken(id, razorToken, () => {
                                  setUpdateToken(false);
                                })
                              )
                          : () => setUpdateToken(true)
                      }
                    >
                      <b>Update token</b>
                    </span>
                  ) : (
                    ""
                  )}
                </Box>
                {/* <Button
              variant="outlined"
              sx={{ width: 200 }}
              disabled={orderStatus !== "placed"}
              onClick={() => props.setOpen(id)}
            >
              Add Item
            </Button> */}
              </Box>

              {/* <Box sx={{ m: 1, flex: 1 }}> */}
              {/* <Box>
              <QRCodeCanvas
                value={`https://uatadmin.thenewshop.in/detail-page-for-delivery/${stateInvoiceNo}`}
              />
            </Box> */}
              {/* <Button
                  variant="outlined"
                  sx={{ ml: 2 }}
                  onClick={() => dispatch(orderInvoice(stateInvoiceNo))}
                >
                  Print Invoice
                </Button> */}
              {/* {zomato !== null && pickupMode !== "self" && (
                  <Box sx={{ flex: 1, m: 1 }}>
                    <p>
                      <b>Zomato Status</b>:
                      {isLoading === true
                        ? "Loading...."
                        : zomatoData?.order_status}
                    </p>
                    {zomatoData?.driver_name && (
                      <>
                        <p>
                          <b>Driver Name</b>:
                          {isLoading === true
                            ? "Loading...."
                            : zomatoData?.driver_name}
                        </p>
                        <p>
                          <b>Driver Phone Number</b>:
                          {isLoading === true
                            ? "Loading...."
                            : zomatoData?.driver_phone}
                        </p>
                        <p>
                          <b>Vechile Name</b>:
                          {isLoading === true
                            ? "Loading...."
                            : zomatoData?.vehicle_name}
                        </p>
                        <p>
                          <b>Vechile Number</b>:{" "}
                          {isLoading === true
                            ? "Loading...."
                            : zomatoData?.vehicle_number}
                        </p>
                        <p>
                          <b>Expeted Time Arrival</b>:{" "}
                          {isLoading === true
                            ? "Loading...."
                            : zomatoData?.Eta?.total_eta}
                        </p>
                      </>
                    )}
                  </Box>
                )}
              </Box> */}
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ flex: 1, m: 1 }}>
              <Box
                style={
                  orderStatus == "placed"
                    ? { backgroundColor: "#ffde59" }
                    : orderStatus == "packed"
                    ? { backgroundColor: "lightblue" }
                    : orderStatus == "out for delivery"
                    ? { background: "lightgreen" }
                    : orderStatus == "delivered"
                    ? { background: "lightgreen" }
                    : orderStatus == "cancelled"
                    ? { background: "red", color: "white" }
                    : { background: "lightred" }
                }
                sx={{ p: 2, textAlign: "center" }}
              >
                <b>
                  Status: {orderStatus ? orderStatus : "Click betow to track"}
                </b>
              </Box>
              <Box sx={{ mt: 1, width: "100%" }}>
                {["placed", "packed"].includes(orderStatus) ? (
                  <RedOutLinButton
                    text="Cancel order"
                    onPress={() => dispatch(cancelOrder(id))}
                  />
                ) : (
                  <></>
                )}
              </Box>

              {orderStatus === "placed" ? (
                <Grid item xs={8} sm={6} lg={3} sx={{ mt: 1 }}>
                  {/* <NormalButton
                  className="btn-purple h-100 m-t-10 w-100"
                  buttonText="Accept Order"
                  onPress={() => dispatch(updateOrderStatus(id, "packed"))}
                  // type="submit"
                /> */}
                  <RedOutLinButton
                    text="Accept Order"
                    color="success"
                    onPress={() =>
                      dispatch(updateOrderStatus(id, storeId, "packed"))
                    }
                  />
                </Grid>
              ) : (
                <></>
              )}
              {orderStatus !== "delivered" &&
                orderStatus !== "cancelled" &&
                lat &&
                token !== "" && (
                  <Button
                    variant="outlined"
                    sx={{ width: "100%", marginTop: "10px" }}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://maps.google.com/?q=${lat},${long}`}
                    >
                      Open map
                    </a>
                  </Button>
                )}
              {/* {getUserTypeFromLs() === "SA" && [
                pickupMode === "store" && (
                  <Button
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onClick={() => handleClick("Zomato Rider")}
                  >
                    Assign to Zomato Rider
                  </Button>
                ),
                pickupMode === "zomato" && (
                  <Button
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onClick={() => handleClick("Store Rider")}
                  >
                    Cancel Zomato and Assign Store Rider
                  </Button>
                ),
              ]}
              {internal_order_id ? (
                <Button
                  variant="outlined"
                  sx={{ width: "100%", mt: 1 }}
                  onClick={() => trackZomato(id, zomato)}
                  // disabled={!rider}
                  // disabled={true}
                >
                  Click to refresh status
                </Button>
              ) : (
                <React.Fragment></React.Fragment>
              )} */}
              {/* {!zomato && pickupMode !== "self" && (
            <>
              <Box sx={{ my: 2 }}>
                <Select
                  handleChange={(e) => setRider(e.target?.value)}
                  value={rider}
                  label={"Rider"}
                  // disabled={orderStatus !== "packed"}
                  // disabled={true}
                  option={
                    runnerList && runnerList?.length ? (
                      runnerList?.map((item) => (
                        <MenuItem value={item?.id}>{item?.userName}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">{"No options"}</MenuItem>
                    )
                  }
                  name="rider"
                  //   error={errors.gender}
                />
              </Box>
              <Button
                variant="outlined"
                sx={{ width: "100%" }}
                onClick={() => {
                  if (rider) {
                    dispatch(asignRunner(id, rider));
                    dispatch(updateOrderStatus(id, "out for delivery"));
                  }
                }}
                // disabled={!rider}
                // disabled={true}
              >
                Pick up
              </Button>
            </>
          )} */}
              {/* {!zomato && pickupMode === "self" && (
            <Button
              variant="outlined"
              sx={{ width: "100%",mt:1 }}
              onClick={() => {
                if (rider) {
                  // dispatch(asignRunner(id, rider));
                  dispatch(updateOrderStatus(id, "delivered"));
                }
              }}
              disabled={!rider}
              // disabled={true}
            >
              Mark as delivered
            </Button>
          )} */}
            </Box>
          </div>
          {/* <Box>
        <Box sx={{ flex: 1, m: 1 }}>
          <p>
            <b>Order No</b>:-#1312
          </p>
          <p>
            <b>Order No</b>:-#1312
          </p>
          <p>
            <b>Order No</b>:-#1312
          </p>
          <p>
            <b>Order No</b>:-#1312
          </p>
        </Box>
        <Box sx={{ p: 2, backgroundColor: "yellow", textAlign: "center" }}>
          <b>Status: order packed</b>
        </Box>
        <Box
          sx={{
            mt: 1,
            p: 2,
            backgroundColor: "yellow",
            textAlign: "center",
            fontSize: 22,
            fontWeight: 500,
          }}
        >
          <span>Status: order packed</span>
        </Box>
        <Box>
          <h5>Item</h5>
          <Box sx={{ display: "flex" }}>
            <span> Take out cafe allo tiki burgur </span>
            <ClearIcon /> <span>4</span>
            <h4 style={{ margin: 10 }}>Total Item 5</h4>
          </Box>
          <Box
            sx={{ mt: 1, p: 2, backgroundColor: "yellow", textAlign: "center" }}
          >
            <b>Status: order packed</b>
          </Box>
        </Box>
      </Box> */}
          {/* {lat && (
            <Button
              variant="outlined"
              sx={{ width: "100%" }}
              // onClick={() => {
              //   if (rider) dispatch(asignRunner(id, rider));
              // }}
              // disabled={!rider}
            >
              <a
                target="_blank"
                href={`https://maps.google.com/?q=${lat},${long}`}
              >
                Open map
              </a>
            </Button>
          )} */}
        </>
      )}
    </>
  );
};

export default Index;
