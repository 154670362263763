import * as React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Utils from "../../utility";
import ViewImg from "../modal/fullSizeDialog"
export default function UploadButtons({
  handleChange,
  lable,
  image,
  id,
  onDelete,
  errors,
  disabled,
}) {
  const [open, setOpen] = React.useState(false);
  const handleEdit = () => {
    document.getElementById(id)?.click();
  };
  return (
    <Stack sx={{ m: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          width: 120,
          height: 120,
          border: errors ? "1px dotted red" : "1px dotted black",
          display: "flex",
          alignItems: "center",
          borderRadius: "5px",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {image ? (
          <img
            src={image?.includes("pdf") ? Utils.constants?.pdfLogo : image}
            alt={lable}
            // onClick={() => window.open(image, "_blank")}
            onClick={() => setOpen(true)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
          />
        ) : (
          <IconButton
            disabled={disabled}
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={handleEdit}
          >
            <PhotoCamera />
          </IconButton>
        )}
        {image ? (
          <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
            <DeleteOutlineIcon
              size="14"
              sx={{ fontSize: "1.0rem" }}
              onClick={onDelete}
            />
            <EditIcon
              size="10"
              sx={{ fontSize: "1.0rem" }}
              onClick={handleEdit}
            />
          </Box>
        ) : (
          <></>
        )}
      </Stack>
      <Box sx={{ textAlign: "center" }}>{lable}</Box>
      <input
        hidden
        accept="image/*,application/pdf"
        type="file"
        id={id}
        onChange={handleChange}
      />
      {errors && (
        <p
          style={{
            // fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "0.75rem",
            color: "red",
            textAlign: "center",
          }}
        >
          {errors}
        </p>
      )}
      <ViewImg open={open} setOpen={setOpen} image={image} />
    </Stack>
  );
}
