import axios from "axios";
import Utils from "../../utility";
// import { getToken } from "../../utility/constant";
import {
  getUserIdFromLs,
  // getUserNameFromLs,
} from "../../utility/commonFunction";
import moment from "moment";
export const getDashboardData = (values) => {
  return (dispatch) => {
    const {
      storeId,
      // storeName,
      // productName,
      state,
      city,
      toDate,
      fromDate,
      // productId,
    } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.dashboard,
      `${storeId ? `?store=${storeId}` : ""}${
        fromDate ? `&fromdate=${fromDate}` : ""
      }${toDate ? `&toDate=${toDate}` : ""}${state ? `&state=${state}` : ""}${
        city ? `&city=${city}` : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            dashboard: respData.data,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getDashboardEmbedUrl = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // axios
    //   .post(
    //     "https://paytm.thenewshop.in/v2/api/v1/quicksight",
    //     {
    //       dashboard_id: id,
    //     },
    //     {
    //       headers: {
    //         Authorization: getToken(),
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {});
    // &userType=1
    Utils.api.postApiCall(
      `${
        window.location.href.includes("https://admin.thenewshop.in")
          ? "https://services.thenewshop.in/"
          : "https://uatservices.thenewshop.in/"
      }api/v1/quicksight`,
      {
        dashboard_id: id,
      },
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            url: respData.data?.value?.EmbedUrl,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(
        //   2,
        //   `quicksight POST Method error ${
        //     JSON.stringify(data?.message) || data?.error
        //   }`
        // );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getDashboardTrend = (storeId) => {
  // debugger;
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      "https://analytics.thenewshop.in/dashboard-trends",
      { storeId, userId: getUserIdFromLs() },
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            dashboardChartData: respData.data,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `dashboard-trends POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getDashboardDetail = (values) => {
  // debugger;
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { storeId, toDate, fromDate } = values;
    const dataToSend = {
      storeId: storeId || "*",
      userId: getUserIdFromLs(),
      fromDate: moment(fromDate).format("YYYY-MM-DD") + " 00:00:00",
      toDate: moment(toDate).format("YYYY-MM-DD") + " 23:59:59",
    };
    Utils.api.postApiCall(
      "https://analytics.thenewshop.in/dashboard-detail",
      dataToSend,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            dashboardDetailData:
              respData.data == "NO PAGE NOT FOUND" ? [] : respData.data,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `dashboard-detail POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * trend data
 *
 */

export const getTrendData = () => {
  return (dispatch, getState) => {
    const { fromDate, toDate, timeFrame, storeId } =
      getState().dashboardReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.trends + storeId,
      `?fromDate=${fromDate}&toDate=${toDate}&trendPeriod=${timeFrame}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            dashboardTrendData: respData.data,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * get chanel Data
 */

export const getChanelData = () => {
  return (dispatch, getState) => {
    const { fromDate, toDate, timeFrame, storeId } =
      getState().dashboardReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.chanelDashboard + storeId,
      `?fromDate=${fromDate}&toDate=${toDate}&trendPeriod=${timeFrame}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
// debugger
        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            chanelData: respData.data?.salesChannels,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * get Inventory Data
 */

export const getTopPerformingProduct = () => {
  return (dispatch, getState) => {
    const {
      fromDate,
      toDate,
      timeFrame,
      storeId,
      takeP,
      orderP,
      sortFieldP,
      offsetP,
    } = getState().dashboardReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.topPerformingProduct + storeId,
      `?order=${orderP}&offset=${offsetP}&take=${takeP}&fromDate=${fromDate}&toDate=${toDate}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            topPerformingProduct: respData.data.data,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * top Performing category
 */

export const getTopPerformingCategory = () => {
  return (dispatch, getState) => {
    const {
      fromDate,
      toDate,
      timeFrame,
      storeId,
      order,
      sortField,
      offset,
      take,
      search,
    } = getState().dashboardReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.topPerformingCategory + storeId,
      // `?fromDate=${fromDate}&toDate=${toDate}`,
      `?order=${order}&offset=${offset}&take=${take}&fromDate=${fromDate}&toDate=${toDate}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            topPerformingCategory: respData?.data?.data,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * get Inventory
 */

export const getInventory = () => {
  return (dispatch, getState) => {
    const { fromDate, toDate, timeFrame, storeId } =
      getState().dashboardReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.inventories + storeId,
      `?fromDate=${fromDate}&toDate=${toDate}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            inventoriesCount: respData?.data.totalInventoryValue,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * get Dead Inventory
 */

export const getDeadInventory = () => {
  return (dispatch, getState) => {
    const { fromDate, toDate, timeFrame, storeId } =
      getState().dashboardReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.deadInventories + storeId,
      `?fromDate=${fromDate}&toDate=${toDate}&trendPeriod=${timeFrame}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            deadInventoriesCount: respData?.data?.deadInventoryCount,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * get Out of stock
 */
export const getOutOfStock = () => {
  return (dispatch, getState) => {
    const { fromDate, toDate, timeFrame, storeId } =
      getState().dashboardReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.outOfStock + storeId,
      `?fromDate=${fromDate}&toDate=${toDate}&trendPeriod=${timeFrame}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            outOfStockCount: respData.data?.outofstock,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * discardValue
 */

export const getDiscardValue = () => {
  return (dispatch, getState) => {
    const { fromDate, toDate, timeFrame, storeId } =
      getState().dashboardReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // &userType=1
    Utils.api.getApiCall(
      Utils.endPoints.discadValue + storeId,
      `?fromDate=${fromDate}&toDate=${toDate}&trendPeriod=daily`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.DASHBOARD,
          payload: {
            discardValue: respData?.data?.discard,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**dead Inv report */

export const getDeadInvReport = () => {
  return (dispatch, getState) => {
    const { fromDate, toDate, storeId } = getState().dashboardReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.deadInventoriesCSV + storeId,
      `?fromDate=${moment(fromDate).format(
        "YYYY-MM-DD"
      )} 00:00:00&toDate=${moment(toDate).format("YYYY-MM-DD")} 23:59:59`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        if (!respData.data?.length) {
          Utils.showAlert(2, "No data found");
          return;
        }
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `deadInvReport.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        // setTimeout(() => {
        //   csvDownload.current.link.click();
        // }, 500);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.RTVStoreStockReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * out of stock report
 */

export const getoutOfStockReport = () => {
  return (dispatch, getState) => {
    const { fromDate, toDate, storeId } = getState().dashboardReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.outOfStockReport + storeId,
      `?fromDate=${moment(fromDate).format(
        "YYYY-MM-DD"
      )} 00:00:00&toDate=${moment(toDate).format("YYYY-MM-DD")} 23:59:59`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        if (!respData.data?.length) {
          Utils.showAlert(2, "No data found");
          return;
        }
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `out-of-stock-report.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        // setTimeout(() => {
        //   csvDownload.current.link.click();
        // }, 500);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.RTVStoreStockReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
