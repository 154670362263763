import * as React from "react";
import { useState, useRef } from "react";
import Utils from "../../../utility";
// import { CSVLink } from "react-csv";
import { getB2BPOkList, getInvoices, getPOkList } from "./action";
import { useReactToPrint } from "react-to-print";
import { Formik, Form } from "formik";
import Schema from "../../../schema";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
import Select from "../../../component/select";
// import {
//   deleteStore,
//   getBiller,
//   getBrandList,
//   getOprationManagerList,
//   getPropertyOwner,
//   // getStoreList,
// } from "./action";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
// import Stack from "@mui/material/Stack";
// import Modal from "../../../component/modal";
// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
// import { borderBottom } from "@mui/system";
import moment from "moment";
import { Grid, MenuItem } from "@mui/material";
import NormalButton from "../../../component/button";
import { getUserTypeFromLs } from "../../../utility/commonFunction";

export default function EnhancedTable({ setOpen, type }) {
  const dispatch = useDispatch();
  const { startDate, endDate, storeId, vender } = useSelector(
    (state) => state.POReducer
  );
  const initialValues = {
    storeId: storeId ? storeId : null,
    storeName: "",
    productName: "",
    endDate: endDate ? endDate : moment(),
    startDate: startDate,
    productId: null,
    vendor: vender ? vender : "",
    status: "",
  };
  const {
    storeListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // warehouseListForDropdown,
  } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={Schema.SKUTimelineSchema}
        onSubmit={(values, { setSubmitting }) => {
          // debugger;
          setOpen(false);
          dispatch({
            type: Utils.ActionName.PO,
            payload: values,
          });
          if (type == "b2b") {
            dispatch(getB2BPOkList());
            return;
          } else if (type == "invoice") dispatch(getInvoices());
          else if (!type) dispatch(getPOkList(setOpen));
          // setInitialValue(values);
          // dispatch(getSKUTimeline(values));
          // if (isEdit?.length) {
          //   dispatch(updateUser(values, setOpen, isEdit[0].id, setIsEdit));
          // } else {
          //
          // }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container rowSpacing={3} columnSpacing={2}>
              {type != "invoice" && (
                <Grid item xs={12} sm={6} lg={12}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.storeId}
                    onChange={(event, value) => {
                      if (value !== null) {
                        const index = storeListForDropdown.findIndex(
                          (x) => x.id === value.id
                        );

                        setFieldValue("storeId", value?.id);
                        setFieldValue("storeName", value?.storename);
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuData: [] },
                        });
                      }
                    }}
                    helperText={touched.storeId && errors.storeId}
                    error={Boolean(touched.storeId && errors.storeId)}
                    name={"storeId"}
                    label={"Store Name"}
                    touched={touched.storeId}
                    options={storeListForDropdown}
                    getOptionLabel={(option) => option?.storename}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = storeListForDropdown?.findIndex(
                        (x) => x?.id == values.storeId
                      );
                      return storeListForDropdown[index];
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} lg={12}>
                <AutoComplete
                  freeSolo={false}
                  // onBlur={handleBlur}
                  values={values.vendor}
                  onChange={(event, value) => {
                    if (value !== null) {
                      setFieldValue("vendor", value?.id);
                    }
                  }}
                  helperText={touched.vendor && errors.vendor}
                  error={Boolean(touched.vendor && errors.vendor)}
                  name={"vendor"}
                  label={"Vedor"}
                  touched={touched.vendor}
                  options={brandOwnerListForDropdown}
                  getOptionLabel={(option) => option?.alias}
                  // isRequired={true}
                  getOptionSelected={(option, value) => {
                    return `${option.id}` === `${value.id}`;
                  }}
                  defaultValue={() => {
                    const index = storeListForDropdown?.findIndex(
                      (x) => x?.id == values.vendor
                    );
                    return storeListForDropdown[index];
                  }}
                />
              </Grid>
              {type != "invoice" && (
                <Grid item xs={12} sm={6} lg={12}>
                  <Select
                    handleChange={handleChange}
                    value={values.status}
                    name={`status`}
                    label={"Status"}
                    option={Object.keys(Utils.constants.newPoEnum).map(
                      (key, index) => (
                        <MenuItem key={index} value={key}>
                          {Utils.constants.newPoEnum[key]}
                        </MenuItem>
                      )
                    )}
                  />
                </Grid>
              )}
              {/* <Grid item xs={12} sm={6} lg={4}>
                <AutoComplete
                  freeSolo={false}
                  // onBlur={handleBlur}
                  values={values.vendor}
                  onChange={(event, value) => {
                    if (value !== null) {
                      const index = brandOwnerListForDropdown.findIndex(
                        (x) => x.id === value.id
                      );
                      setFieldValue("vender", value?.id);
                      // setFieldValue("productName", value?.name);
                      // dispatch({
                      //   type: Utils.ActionName.SKU_TIMELINE,
                      //   payload: { skuData: [] },
                      // });
                    }
                  }}
                  helperText={touched.vendor && errors.vendor}
                  error={Boolean(touched.vendor && errors.vendor)}
                  name={"vendor"}
                  label={"Vendor"}
                  touched={touched.vendor}
                  options={brandOwnerListForDropdown}
                  getOptionLabel={(option) => option?.alias}
                  // isRequired={true}
                  getOptionSelected={(option, value) => {
                    return `${option.id}` === `${value.id}`;
                  }}
                  defaultValue={() => {
                    const index = brandOwnerListForDropdown?.findIndex(
                      (x) => x?.id == values?.productId
                    );
                    return brandOwnerListForDropdown[index];
                  }}
                />
              </Grid> */}
              {type != "invoice" && (
                <>
                  <Grid item xs={12} sm={6} lg={6}>
                    <DatePicker
                      value={values.startDate}
                      name="startDate"
                      handleChange={(newVal) =>
                        setFieldValue("startDate", newVal)
                      }
                      label="From Date"
                      error={errors.startDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <DatePicker
                      value={values.endDate}
                      name="endDate"
                      handleChange={(newVal) =>
                        setFieldValue("endDate", newVal)
                      }
                      label="To Date"
                      error={errors.endDate}
                    />
                  </Grid>
                </>
              )}
           
            </Grid>
            <hr className="m-t-20 m-b-20" />

            <div className="Dflex al-cnt fl-end">
              <NormalButton
                className="btn-simple"
                buttonText="Clear filter"
                onPress={() => {
                  setOpen(false);
                  dispatch({
                    type: Utils.ActionName.PO,
                    payload: {
                      status: "",
                      storeId: "",
                      vendor: "",
                      endDate: moment(),
                      startDate: moment(new Date())
                        .subtract(30, "d")
                        .format("YYYY-MM-DD"),
                    },
                  });
                  if (type == "invoice") {
                    dispatch(getInvoices());
                    return;
                  }
                  if (type == "b2b") {
                    dispatch(getB2BPOkList());
                    return;
                  } else {
                    if (getUserTypeFromLs() === Utils.constants.UserTypeObj.SA)
                      dispatch(getPOkList(values));
                  }
                }}
              />
              <NormalButton
                className="btn-purple m-l-30"
                buttonText="Submit"
                // onPress={() => {}}
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>

      {/* <button >!</button> */}
    </>
  );
}
