import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function CheckboxLabels({
  value,
  name,
  handleChange,
  label,
  disabled,
}) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            name={name}
            onChange={handleChange}
            checked={value}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
