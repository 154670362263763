import React from "react";
import { TextField, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutocompleteMultiple({
  label,
  placeholder,
  id,
  name,
  type,
  onBlur,
  values,
  onChange,
  style,
  helperText,
  isRequired,
  error,
  options,
  getOptionLabel,
  onInputChange,
  getOptionSelected,
  inputValue,
  defaultValue,
  onClose,
  isDisabled,
  renderOption,
}) {
  return (
    <Box
      sx={{
        width: "100%",
        pr: 2,
        maxWidth: "100%",
      }}
    >
      <Autocomplete
        // {...defaultProps}
        freeSolo={true}
        getOptionLabel={getOptionLabel}
        id="auto-select"
        autoSelect={false}
        options={options}
        // defaultValue={''}
        multiple
        label={label}
        // getOptionLabel={getOptionLabel}
        disabled={isDisabled}
        onChange={onChange}
        // disableClearable
        disableCloseOnSelect
        renderOption={renderOption}
        onClose={onClose}
        // inputValue={inputValue ? inputValue : ''}
        // getOptionSelected={(item: any, current: any) => item.value === current.value}
        onInputChange={onInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            // label={label}
            disabled={isDisabled}
            label={label}
            // margin="normal"
            helperText={helperText}
            error={error}
            name={name}
            // onChange={onChange}
            type={type}
            value={values ? values : ""}
            defaultValue={values}
            // defaultValue={''}
            // required={isRequired}
          />
        )}
        onBlur={onBlur}
        // value={values}

        placeholder={placeholder}
        fullWidth
        size={"small"}
        // className={classes.inputField}
        // getOptionSelected={getOptionSelected}
        // defaultValue={'-'}
        defaultValue={defaultValue}
      />
    </Box>
  );
}
