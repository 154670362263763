import Utils from "../utility";

const initialState = {
  loading: false,
  productLoad: false,
};
export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.LOADING}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
