import React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CheckBox from "../../../component/checkbox";
import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import moment from "moment";
import NormalButton from "../../../component/button";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { addUser, updateUser } from "./action";
import Input from "../../../component/input";

import Select from "../../../component/select";
import Utils from "../../../utility";
const AddUser = ({ setOpen, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();

  const initialValues = {
    userType: isEdit?.length ? isEdit[0].userType : "",
    fullName: isEdit?.length ? isEdit[0].name : "",
    username: isEdit?.length ? isEdit[0].userName : "",
    password: isEdit?.length ? isEdit[0].password : "",
    phone: isEdit?.length ? isEdit[0].contactNumber : "",
    email: isEdit?.length ? isEdit[0].contactEmail : "",
    address: isEdit?.length ? isEdit[0].address : "",
    state: isEdit?.length ? isEdit[0].state : "",
    city: isEdit?.length ? isEdit[0].city : "",
    pincode: isEdit?.length ? isEdit[0].pincode : "",
    PAN: isEdit?.length ? isEdit[0].PAN : "",
    aadhar: isEdit?.length ? isEdit[0].aadhar : "",
    officeLocation: isEdit?.length ? isEdit[0].officeLocation : "",
    post: isEdit?.length ? isEdit[0].post : "",
    isActive: isEdit?.length ? isEdit[0].isActive : false,
    leaveDate: isEdit?.length ? isEdit[0].leaveDate : moment(),
    propertyOwner: isEdit?.length ? isEdit[0].propertyOwnerId : "",
    brandOwner: isEdit?.length ? isEdit[0].brandId : "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema.addUserSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (isEdit?.length) {
          dispatch(
            updateUser(values, setOpen, isEdit[0].id, setIsEdit, setSubmitting)
          );
        } else {
          dispatch(addUser(values, setOpen, setSubmitting));
        }
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
           {/* {console.log(Utils.constants.cities[values?.state],"shashank")} */}
          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <Select
                handleChange={handleChange}
                value={values.userType}
                label={"User Type"}
                option={Utils.constants.UserType.map((item) => (
                  <MenuItem key={item.text} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
                name="userType"
                error={errors.userType}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.fullName}
                name="fullName"
                type="text"
                error={errors.fullName}
                label="Full Name"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {isEdit?.length ? (
                <></>
              ) : (
                <Input
                  value={values.password}
                  name="password"
                  type="text"
                  error={errors.password}
                  label="Password"
                  onChange={handleChange}
                  handleBlur={handleBlur}
                />
              )}
            </Grid>
          </Grid>

          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.username}
                name="username"
                type="text"
                error={errors.username}
                label="Username"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.phone}
                name="phone"
                type="text"
                error={errors.phone}
                label="Phone"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.email}
                name="email"
                type="text"
                error={errors.email}
                label="Email"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <Select
                handleChange={handleChange}
                value={values.state}
                label={"State"}
                option={Utils.constants.states.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
                name="state"
                error={errors.state}
              />
            </Grid>
           
            <Grid item xs={12} sm={6} lg={4}>
              {/* {values.state && ( */}
              <Select
                handleChange={handleChange}
                value={values.city}
                label={"City"}
                disabled={!values.state}
                option={Utils.constants.cities[values?.state]?.map((item) => (
                  <MenuItem key={item.city} value={item.city}>
                    {item.city}
                  </MenuItem>
                ))}
                name="city"
                error={errors.city}
              />
              {/* )} */}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.address}
                name="address"
                type="text"
                error={errors.address}
                label="Address"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.pincode}
                name="pincode"
                type="number"
                error={errors.pincode}
                label="Pincode"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.PAN}
                name="PAN"
                type="text"
                error={errors.PAN}
                label="PAN"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.aadhar}
                name="aadhar"
                type="number"
                error={errors.aadhar}
                label="Aadhar"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.officeLocation}
                name="officeLocation"
                type="text"
                error={errors.officeLocation}
                label="Office Location"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.post}
                name="post"
                type="text"
                error={errors.post}
                label="Post"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <DatePicker
                value={values.leaveDate}
                name="leaveDate"
                handleChange={(newVal) => setFieldValue("leaveDate", newVal)}
                label="Leave Date"
                error={errors.leaveDate}
              />
            </Grid>
          </Grid>
          <CheckBox
            value={Boolean(values.isActive)}
            name="isActive"
            label="Is Active"
            handleChange={handleChange}
          />

          {/* <Button variant="outlined" type="submit" disabled={isSubmitting}>
            {isEdit?.length ? "update User" : "Add User"}
          </Button> */}
          <hr className="m-t-20 m-b-20" />

          <div className="Dflex al-cnt fl-end">
            <NormalButton
              className="btn-simple"
              buttonText="Cancel"
              onPress={() => setOpen(false)}
            />
            <NormalButton
              className="btn-purple m-l-30"
              buttonText={isEdit?.length ? "update User" : "Add User"}
              // onPress={() => {}}
              type="submit"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
