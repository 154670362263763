import * as React from "react";
import { useState, useRef } from "react";
import Utils from "../../../utility";
// import { CSVLink } from "react-csv";
// import { getPOkList } from "./action";
import { useReactToPrint } from "react-to-print";
import { Formik, Form } from "formik";
import Schema from "../../../schema";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
// import {
//   deleteStore,
//   getBiller,
//   getBrandList,
//   getOprationManagerList,
//   getPropertyOwner,
//   // getStoreList,
// } from "./action";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
// import Stack from "@mui/material/Stack";
// import Modal from "../../../component/modal";
// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
// import { borderBottom } from "@mui/system";
import moment from "moment";
import { Grid } from "@mui/material";
import NormalButton from "../../../component/button";
import { getStoreStockList } from "./action";

export default function EnhancedTable({ storeId, setOpen }) {
  const dispatch = useDispatch();
  const {
    // brandsForDropdown,
    brandId,
    categoryId,
    // warehouseListForDropdown,
  } = useSelector((state) => state.storeStockReducer);
  const initialValues = {
    categoryId: categoryId || "",
    brandId: brandId || "",
  };
  const {
    // brandsForDropdown,
    brandsForDropdown,
    categoryListForDropDown,
    // warehouseListForDropdown,
  } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={Schema.SKUTimelineSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch({
            type: Utils.ActionName.STORE_STOCK,
            payload: values,
          });
          // debugger;
          dispatch(getStoreStockList(storeId, () => setOpen(false)));
          // setInitialValue(values);
          // dispatch(getSKUTimeline(values));
          // if (isEdit?.length) {
          //   dispatch(updateUser(values, setOpen, isEdit[0].id, setIsEdit));
          // } else {
          //
          // }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} sm={6} lg={12}>
                <AutoComplete
                  freeSolo={false}
                  // onBlur={handleBlur}
                  values={values.brandId}
                  onChange={(event, value) => {
                    if (value !== null) {
                      const index = brandsForDropdown.findIndex(
                        (x) => x.id === value.id
                      );

                      setFieldValue("brandId", value?.id);
                      setFieldValue("storeName", value?.storename);
                      dispatch({
                        type: Utils.ActionName.SKU_TIMELINE,
                        payload: { skuData: [] },
                      });
                    }
                  }}
                  helperText={touched.brandId && errors.brandId}
                  error={Boolean(touched.brandId && errors.brandId)}
                  name={"brandId"}
                  label={"Brand"}
                  touched={touched.brandId}
                  options={brandsForDropdown}
                  getOptionLabel={(option) => option?.brandName}
                  // isRequired={true}
                  getOptionSelected={(option, value) => {
                    return `${option.id}` === `${value.id}`;
                  }}
                  defaultValue={() => {
                    const index = brandsForDropdown?.findIndex(
                      (x) => x?.id == values.brandId
                    );

                    return brandsForDropdown[index];
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <AutoComplete
                  freeSolo={false}
                  // onBlur={handleBlur}
                  values={values.categoryId}
                  onChange={(event, value) => {
                    if (value !== null) {
                      const index = categoryListForDropDown.findIndex(
                        (x) => x.id === value.id
                      );
                      setFieldValue("categoryId", value?.id);
                      // setFieldValue("productName", value?.name);
                      // dispatch({
                      //   type: Utils.ActionName.SKU_TIMELINE,
                      //   payload: { skuData: [] },
                      // });
                    }
                  }}
                  helperText={touched.categoryId && errors.categoryId}
                  error={Boolean(touched.categoryId && errors.categoryId)}
                  name={"categoryId"}
                  label={"category"}
                  touched={touched.categoryId}
                  options={categoryListForDropDown}
                  getOptionLabel={(option) => option?.categoryName}
                  // isRequired={true}
                  getOptionSelected={(option, value) => {
                    return `${option.id}` === `${value.id}`;
                  }}
                  defaultValue={() => {
                    const index = categoryListForDropDown?.findIndex(
                      (x) => x?.id == values?.categoryId
                    );
                    // debugger;
                    return categoryListForDropDown[index];
                  }}
                />
              </Grid>
            </Grid>
            <hr className="m-t-20 m-b-20" />

            <div className="Dflex al-cnt fl-end">
              <NormalButton
                className="btn-simple"
                buttonText="Clear"
                onPress={() => {
                  dispatch({
                    type: Utils.ActionName.STORE_STOCK,
                    payload: {
                      categoryId: "",
                      brandId: "",
                    },
                  });
                  dispatch(getStoreStockList(storeId, () => setOpen(false)));
                }}
              />
              <NormalButton
                className="btn-purple m-l-30"
                buttonText="Submit"
                // onPress={() => {}}
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>

      {/* <button >!</button> */}
    </>
  );
}
